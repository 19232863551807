<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Producto' }, { name: 'Editar' }]" />
    <v-card style="border-radius: 10px 10px 0 0">
      <v-toolbar dark card color="primary" style="border-radius: 10px 10px 0 0">
        <v-toolbar-title>{{ form.nombre }} </v-toolbar-title>
        <v-spacer />
        <v-btn
          large
          :to="{ name: 'ListadoProductItems', params: { id: $route.params.id } }"
          color="success"
        >
          <svg style="width: 24px; height: 24px; margin-right: 10px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M9,5V9H21V5M9,19H21V15H9M9,14H21V10H9M4,9H8V5H4M4,19H8V15H4M4,14H8V10H4V14Z"
            />
          </svg>
          Ver Items
        </v-btn>
      </v-toolbar>
      <v-bottom-nav :active.sync="activeBtn" color="transparent" :value="true">
        <v-btn :to="{ name: 'EditProduct', params: { id: $route.params.id } }" flat color="teal">
          <span>Informacion</span>
          <v-icon>assignment</v-icon>
        </v-btn>
        <v-btn
          :to="{ name: 'ListaProductAtributo', params: { id: $route.params.id } }"
          flat
          color="teal"
        >
          <span>Atributos</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn
          :to="{ name: 'ListaProductImagen', params: { id: $route.params.id } }"
          flat
          color="teal"
        >
          <span>Imágenes</span>
          <v-icon>image</v-icon>
        </v-btn>
        <v-btn
          :to="{ name: 'ListProductoEtiquetas', params: { id: $route.params.id } }"
          flat
          color="teal"
        >
          <span>Etiquetas</span>
          <v-icon>description</v-icon>
        </v-btn>
        <v-btn :to="{ name: 'ListRating', params: { id: $route.params.id } }" flat color="teal">
          <span>Ratings</span>
          <v-icon>star</v-icon>
        </v-btn>
      </v-bottom-nav>
      <br />
      <div v-if="loadingProducto" class="text-xs-center" style="padding: 25px">
        <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
      </div>
      <div v-else>
        <v-toolbar color="grey darken-1" dark card>
          <v-toolbar-title>Editar Producto</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-container fluid grid-list-lg>
          <v-layout row wrap>
            <v-card>
              <v-divider />
              <v-card-text class="pa-0">
                <v-form
                  ref="form"
                  v-model="validForm"
                  lazy-validation
                  @submit.prevent="submitCreateProducto"
                >
                  <v-container fluid grid-list-lg>
                    <v-layout row wrap>
                      <v-flex md12 sm12 xs12>
                        <v-autocomplete
                          v-model="form.categoria_id"
                          :items="categorias"
                          dense
                          outline
                          clearable
                          small-chips
                          label="Seleccionar Categoria"
                          item-text="nombre"
                          item-value="id"
                          :disabled="processingForm"
                          :error="!!formErrors.categoria_id"
                          :error-messages="formErrors.categoria_id"
                          @change="
                            () => {
                              formErrors.categoria_id = undefined;
                              delete formErrors.categoria_id;
                            }
                          "
                        />
                      </v-flex>
                      <!-- <v-flex md12 sm12 xs12>
                        <v-autocomplete
                          v-model="tipo"
                          :items="tipos"
                          dense
                          outline
                          readonly
                          small-chips
                          label="Tipo"
                        />
                      </v-flex> -->
                      <!-- <v-flex md12 sm12 xs12>
                        <v-text-field
                          v-model="form.SKU"
                          :disabled="processingForm"
                          label="SKU"
                          outline
                          :rules="rules.SKU"
                          :error="!!formErrors.SKU"
                          :error-messages="formErrors.SKU"
                          @keyup="
                            () => {
                              formErrors.SKU = undefined;
                              delete formErrors.SKU;
                            }
                          "
                        />
                      </v-flex> -->
                      <v-flex md12 sm12 xs12>
                        <v-text-field
                          v-model="form.nombre"
                          :disabled="processingForm"
                          label="Nombre"
                          outline
                          :rules="rules.nombre"
                          :error="!!formErrors.nombre"
                          :error-messages="formErrors.nombre"
                          @keyup="
                            () => {
                              formErrors.nombre = undefined;
                              delete formErrors.nombre;
                            }
                          "
                        />
                      </v-flex>
                      <v-flex md12 sm12 xs12>
                        <v-text-field
                          v-model="form.posicion"
                          :disabled="processingForm"
                          label="Posición"
                          outline
                          :rules="rules.posicion"
                          :error="!!formErrors.posicion"
                          :error-messages="formErrors.posicion"
                          @keyup="
                            () => {
                              formErrors.posicion = undefined;
                              delete formErrors.posicion;
                            }
                          "
                        />
                      </v-flex>
                      <v-flex md12 sm12 xs12>
                        <v-text-field
                          v-model="form.descripcion_corta"
                          :disabled="processingForm"
                          label="Descripcion para SEO"
                          outline
                          :rules="rules.descripcion_corta"
                          :error="!!formErrors.descripcion_corta"
                          :error-messages="formErrors.descripcion_corta"
                          @keyup="
                            () => {
                              formErrors.descripcion_corta = undefined;
                              delete formErrors.descripcion_corta;
                            }
                          "
                        />
                      </v-flex>
                      <v-flex md12 sm12 xs12>
                        <h4>Descripción Larga</h4>
                        <br />
                        <ckeditor v-model="form.descripcion_larga"></ckeditor>
                        <!-- <v-textarea
                          v-model="form.descripcion_larga"
                          label="Descripcion larga"
                          rows="3"
                          outline
                        ></v-textarea> -->
                      </v-flex>
                      <v-flex md12 sm12 xs12>
                        <v-autocomplete
                          v-model="form.estado"
                          :items="estados"
                          dense
                          outline
                          clearable
                          small-chips
                          label="Seleccionar Estado"
                          item-text="nombre"
                          item-value="id"
                          :disabled="processingForm"
                          :error="!!formErrors.estado"
                          :error-messages="formErrors.estado"
                          @change="
                            () => {
                              formErrors.estado = undefined;
                              delete formErrors.estado;
                            }
                          "
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <v-divider class="mb-3" />
                  <div class="text-xs-center pb-3">
                    <v-btn
                      type="submit"
                      color="success"
                      :disabled="!validForm || processingForm"
                      :loading="processingForm"
                      >Guardar Cambios</v-btn
                    >
                    <v-btn color="error" @click="$router.push({ name: 'ListaProducto' })">
                      Volver
                    </v-btn>
                  </div>
                </v-form>
              </v-card-text>
            </v-card>
          </v-layout>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return { title: "informacion" };
  },

  components: {
    NotPermission: () => import("@/views/errors/NotPermission"),
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
  },
  data() {
    return {
      activeBtn: 0,
      loadingProducto: false,
      formErrors: {},
      form: {
        SKU: "",
        nombre: "",
        descripcion_corta: "",
        descripcion_larga: "",
        estado: "",
        categoria_id: "",
        posicion: "",
      },
      tipo: "",
      estados: [
        { id: false, nombre: "inactivo" },
        { id: true, nombre: "activo" },
      ],
      tipos: ["item"],
      validForm: true,
      processingForm: false,
      rules: {
        nombre: [(v) => !!v || "El nombre es requerido"],
      },
    };
  },

  computed: {
    ...mapState({
      categorias: (state) => state.categorias.categorias,
      loadingCategorias: (state) => state.categorias.loadingCategorias,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
  },
  async mounted() {
    this.loadingProducto = true;
    await this.getCategorias();
    await this.getProducto({ productoId: this.$route.params.id }).then((response) => {
      const productoInfo = response.data.data;
      this.setForm(productoInfo);
    });
    this.loadingProducto = false;
  },

  methods: {
    ...mapActions({
      updateProducto: "productos/updateProducto",
      getCategorias: "categorias/getCategorias",
      getProducto: "productos/getProducto",
    }),
    setForm(producto) {
      this.form.SKU = producto.SKU;
      this.form.nombre = producto.nombre;
      this.form.descripcion_corta = producto.descripcion_corta;
      this.form.descripcion_larga = producto.descripcion_larga;
      this.form.estado = producto.estado;
      this.form.categoria_id = producto.categoria.id;
      this.tipo = producto.tipo;
      this.form.posicion = producto.posicion;
    },
    submitCreateProducto() {
      if (!this.$refs.form.validate()) return false;
      this.processingForm = true;
      this.updateProducto({ productoId: this.$route.params.id, data: this.form })
        .then((response) => {
          this.processingForm = false;
        })
        .catch((error) => {
          this.processingForm = false;
          this.formErrors = error.response.data.errors || {};
        });
    },
  },
};
</script>
